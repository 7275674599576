<template>
  <Page
    v-if="!objectIsEmpty(req)"
    :optin="req"
    :is-edit="true"
    :merchant="merchant"
    @click-update="updateOptIn"
    @click-cancel="cancelOptIn"
  />
</template>

<script>
import '@validations';

import Ripple from 'vue-ripple-directive';

import OptInService from '@/services/osiris/OptInService';
import CercOptInService from '@/services/osiris/Cerc/OptInService';

import TagMapper from '../mappers/Tag';
import CercMapper from '../mappers/Cerc';

import Page from '../components/Page.vue';

const mappers = {
    TAG: TagMapper,
    CERC: CercMapper,
};

const services = {
    TAG: OptInService,
    CERC: CercOptInService,
};

export default {
    components: {
        Page,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            req: {},
            merchant: this.getStoreMerchant(),
        };
    },

    async mounted() {
        const { id } = this.$route.params;

        if (!id) {
            this.$router.push('/error-404');
        }

        this.getOptIn(id);
    },

    methods: {
        async getOptIn(id) {
            this.isLoading(true);

            const promisesResolved = await Promise.all([
                services.CERC.show(id),
                services.TAG.show(id),
            ]);

            const response = promisesResolved.find(item => item?.status === 200);

            if (!response) this.$router.push('/error-404');

            const { data } = response;
            const request = mappers[data.register].toView(data);
            this.req = request;

            this.isLoading(false);
        },

        async updateOptIn(input) {
            this.isLoading(true);
            const { id, start_date, end_date } = input;

            const { status } = await services[input.register].update(id, {
                start_date, end_date,
            });

            const responseStatus = {
                200: 'Opt-In editado com sucesso',
                202: 'Está fora do horário de funcionamento, porém sua edição foi agendada',
            };

            this.isLoading(false);

            if (status === 200 || status === 202) {
                this.modalSuccess(responseStatus[status]);
            } else {
                this.modalError('Não foi possível editar esse Opt-In');
            }
        },

        async cancelOptIn({ id, register }) {
            this.isLoading(true);

            const { status } = await services[register].delete(id);

            const responseStatus = {
                200: 'Opt-In criado com sucesso',
                202: 'Está fora do horário de funcionamento, porém o cancelamento foi agendado',
            };

            this.isLoading(false);

            if (status === 200 || status === 202) {
                this.modalSuccess(responseStatus[status]);
                this.$router.push(`/plataforma-credito/estabelecimento/detalhes/${this.merchant.id}`);
            } else {
                this.modalError('Não foi possível cancelar esse Opt-In');
            }
        },
    },
};
</script>
